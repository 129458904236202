import { makeStyles } from "@material-ui/core/styles";

export const useStylesTicket = makeStyles((theme) => ({
  ticketBlock: {

    
    fontFamily: "Verdana",
    color: "#000000",
    fontSize: 25,
     
    lineHeight: 1,
    borderRadius: 15,
  },
  ticketHeader: {
    padding: "15px",
    color: "#000000",
    textAlign: "center",
    fontWeight: "bold",
  },
  ticketProducts: {
    fontWeight: "bold",
    fontSize: 55,
    color: "#000000",
 
  },
  table: {
    
    color: "#000000",
    "& .MuiTableRow-root": {
      display: "column",
      width: "100%",
      tableLayout: "fixed",
      
       
    },
  },
  ticketFooter: {
    color: "#000000",
    marginTop: "2.375rem",
    fontSize: 15,
    fontWeight: "bold",
    padding: "5px 15px 15px 15px",
  },
  ticket: {

    position: "relative",
    padding: "15px 25px 32px 0px",
    borderRadius: 20,
    
    // background: "#000000",
    "&:after": {
      background: `linear-gradient(-45deg, #ffffff 16px, transparent 0), 
                    linear-gradient(45deg, #ffffff 16px, transparent 0)`,
      backgroundRepeat: "repeat-x",
      backgroundSize: "32px 32px",
      content: '""',
      display: "flex",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",

    },
  },
  tableBody: {
    color: "#000000",
    display: "block",
    height: 200,
    overflow: "auto",
  },
  tableRow: {
    color: "#000000",
    height: 20,
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  tableCell: {
    padding: "7px 8px",
    fontFamily: "Verdana",
    fontWeight: "bold",
    fontSize: 15,
    color: "#000000",
    
    
  },
  duplicata: {
    position: "relative",
    "&::before": {
      content: '"DUPLICATA"',
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      fontSize: "100px",
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.05)", // Transparent color for watermark
      zIndex: 1,
      pointerEvents: "none", // Ensure it doesn't interfere with interactions
    },
  },
}))