import React, { useRef, useState, useEffect } from "react";
import { useStyles } from "../../style";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { formatDate, symbol, calculatePrices } from "../../../../helpers/utils";
import { Button, Typography, Box, Badge } from "@material-ui/core";
import { SetNotification } from "../../../../store/notification/notification.action";
import ReactToPrint from "react-to-print";
import { AvoirComponentToPrint } from "../../../../components/componentToPrint/avoir";
import { SetAvoirToPrint } from "../../../../store/checkout/checkout.action";
import { Voucher } from "../../../../components/componentToPrint/avoir/voucher";

export const PriceResult = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const doc2Print = useRef();
  const { t } = useTranslation(["payement", "common"]);

  const avoir2Print = useSelector((state) => state.Checkout.avoir2Print);
  const selectedCustomer = useSelector((state) => state.Checkout.client);
  const amount = useSelector((state) => state.Checkout.amount);
  const discount = useSelector((state) => state.Checkout.discount);
  const order = useSelector((state) => state.Checkout.order);
  const payments = useSelector((state) => state.Checkout.amount.payments);
  const appliedLoyaltyCard = useSelector(
    (state) => state.Cart.appliedLoyaltyCard
  );
  const client = useSelector((state) => state.Checkout.client);
  const currencies = useSelector((state) => state.Client.currencies);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const taxsales = useSelector((state) => state.Checkout.taxsales);
  const products = useSelector((state) => state.Checkout.products);
  const itemTaxList = useSelector((state) => state.Product.itemTax);
  const itemDetails = useSelector((state) => state.Checkout.itemDetails);

  const [isPending, setIsPending] = useState(false);
  const [itemPrestationList, setItemPrestationListList] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Item",
        fields: [
          "`tabItem`.`name`",
          "`tabItem`.`item_code`",
          "`tabItem`.`prix_de_vente_ht`",
          "`tabItem`.`standard_rate`",
        ],
        filters: [
          ["Item", "is_stock_item", "=", 0],
          ["Item", "item_group", "=", "Services"],
        ],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setItemPrestationListList(data);
        }
      });
  }, []);

  const calculateTaxRate = (item) => {
    const prestationItem = itemPrestationList.find(
      (p) => p.item_code === item.item_code
    );
    if (prestationItem) {
      const standardRate = parseFloat(prestationItem.standard_rate);
      const prixDeVenteHT = parseFloat(prestationItem.prix_de_vente_ht);
      if (standardRate && prixDeVenteHT) {
        return (
          Math.round(
            ((prixDeVenteHT - standardRate) / standardRate) * 100 * 100
          ) / 100
        );
      }
    }
    return (
      itemTaxList?.find((s) => s.name === item.item_tax_template)?.tax_rate || 0
    );
  };

  const calculateTotal = () => {
    return products.reduce((total, product) => {
      const basePrice =
        product.price_list_rate ||
        product.standard_rate ||
        product.basic_rate ||
        0;
      const quantity = parseInt(product.qty) || 1;
      const taxRate = calculateTaxRate(product);
      const unitPriceTTC =
        Math.round(basePrice * (1 + taxRate / 100) * 1000) / 1000;
      return total + unitPriceTTC * quantity;
    }, 0);
  };

  const taxsalesvalue =
    taxsales && !isNaN(taxsales.value) ? parseFloat(taxsales.value) : 0;
  const currencySymbol = client?.default_currency
    ? symbol(client.default_currency, currencies)?.symbol
    : symbol(globalDefaults?.default_currency, currencies)?.symbol;

  const makeAvoir = async () => {
    setIsPending(true);
    const data = {
      doc: {
        credit_amount: getAmount2Return(),
        currency: globalDefaults?.default_currency,
        naming_series: "CUS-AVR-.YYYY.-",
        creation_date: formatDate(Date.now(), "YYYY-MM-DD"),
        customer: selectedCustomer.customer_name,
        company: globalDefaults?.default_company,
        return_against: null,
        amended_from: null,
        doctype: "Avoir Account",
      },
      action: "Submit",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}/api/save-docs`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user"))?.token,
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();
      setIsPending(false);

      if (!response.ok) {
        dispatch(
          SetNotification({ code: "error", message: "Something went wrong" })
        );
      } else if (result?.success === false) {
        dispatch(
          SetNotification({ code: "warning", message: result?.message })
        );
      } else if (result?.docs?.[0]) {
        dispatch(SetAvoirToPrint(result.docs[0]));
        dispatch(
          SetNotification({
            code: "success",
            message: "Avoir a été généré avec succès.",
          })
        );
      }
    } catch (error) {
      setIsPending(false);
      dispatch(
        SetNotification({ code: "error", message: "Failed to generate avoir" })
      );
    }
  };

  const Pricescalculation = () => {
    const prices = calculatePrices({
      products,
      itemDetails,
      ignorePricingRule: false,
      discount,
      taxsalesvalue,
      itemPrestationList
    });
  
    return {
      totalWithTax: prices.subtotal + prices.taxTotal,
      totalAmount: prices.total
    };
  };

  const { totalAmount } = Pricescalculation();
  const totalAmountPayed = payments
  ? payments.reduce((a, v) => a + parseFloat(v.amount), 0)
  : 0;
  const getAmount2Return = () => {
    const finalTotal = parseFloat(totalAmount);
    return totalAmountPayed <= finalTotal ? 
      0.0 : 
      parseFloat((totalAmountPayed - finalTotal).toFixed(3));
  };

  return (
    <Box
      textAlign="center"
      style={{
        margin: 20,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box className={classes.depositBlock} style={{ padding: 30 }}>
        <Typography
          align={"center"}
          className={clsx(classes.total, classes.totalItem)}
        >
          {t("common:total")}:
        </Typography>
        <Typography align={"center"} className={classes.total}>
          {appliedLoyaltyCard?.redeem_points_amount &&
          appliedLoyaltyCard?.redeem_points_amount < parseFloat(totalAmount)
            ? (
                parseFloat(totalAmount) +
                parseFloat(appliedLoyaltyCard?.redeem_points_amount)
              ).toFixed(3)
            : (Math.round(totalAmount * 100) / 100 ).toFixed(3)}{" "}
          {currencySymbol}
        </Typography>
      </Box>
      <Box className={classes.depositBlock} style={{ padding: 30 }}>
        <Typography
          align={"center"}
          className={clsx(classes.total, classes.totalItem)}
        >
          {t("common:montantRendre")}:
        </Typography>
        <Typography
          align={"center"}
          className={classes.total}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 15,
            alignItems: "center",
          }}
        >
          {((Math.round(getAmount2Return()*100))/100).toFixed(3)} {currencySymbol}
          {getAmount2Return() - 0.001 > 0 && order ? (
            avoir2Print ? (
              <ReactToPrint
                trigger={() => (
                  <Badge badgeContent={1} color="error">
                    <Button variant="contained" color="secondary" size="large">
                      Imprimer Avoir
                    </Button>
                  </Badge>
                )}
                content={() => avoir2Print && doc2Print.current}
              />
            ) : (
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={makeAvoir}
                disabled={isPending}
              >
                Générer Avoir
              </Button>
            )
          ) : null}
        </Typography>
      </Box>
      <div className={classes.print}>
        {avoir2Print && (
          <Voucher document={avoir2Print} title="Avoir" ref={doc2Print} />
        )}
      </div>
    </Box>
  );
};
